import type { QueryResult } from '@apollo/client/react/types/types';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import nodeQuery from '@aurora/shared-client/components/nodes/NodeView.query.graphql';
import type {
  NodeViewQuery,
  NodeViewQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import { useContext } from 'react';
import { ItemType, NodeHeaderVariant } from '../../../types/enums';
import useEntityViewQuery from '../../useEntityViewQuery';
import { isNodeAbleToDisplayActionButton } from './NodeBannerWidgetHelper';

/**
 *
 * Returns the new data for the page header
 * @author Manish Shrestha
 * @param useNodeHeaderCountStats whether to use header count stats or not
 * @param nodeHeaderVariant node header variant type
 */
export function useNodePageHeaderQuery(
  useNodeHeaderCountStats: boolean,
  nodeHeaderVariant: NodeHeaderVariant
): QueryResult<NodeViewQuery, NodeViewQueryVariables> {
  const { contextNode } = useContext(AppContext);
  const { id, nodeType } = contextNode;
  const isCommunity = nodeType === NodeType.COMMUNITY;

  const nodeViewQueryVariables: NodeViewQueryVariables = {
    id,
    ...(nodeHeaderVariant === NodeHeaderVariant.MD && { useNodeAvatar: true }),
    ...(useNodeHeaderCountStats && { useNodeTopicsCount: true }),
    ...(!isCommunity && { useNodeDescription: true }),
    ...(isNodeAbleToDisplayActionButton && { useNodeParent: true })
  };

  return useEntityViewQuery<ItemType.NODE, NodeViewQuery, NodeViewQueryVariables>(
    module,
    ItemType.NODE,
    null,
    nodeQuery,
    {
      variables: nodeViewQueryVariables
    }
  );
}
