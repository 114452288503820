import QueryHandler from '@aurora/shared-client/components/common/QueryHandler/QueryHandler';
import QuiltContext from '@aurora/shared-client/components/context/QuiltContext';
import QuiltWrapperContext from '@aurora/shared-client/components/context/QuiltWrapperContext';
import SwitchBranchContext from '@aurora/shared-client/components/context/SwitchBranchContext/SwitchBranchContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import { LastModifiedKeys } from '@aurora/shared-types/assets';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { isObjectEmpty } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { AssetFeature, getQuiltAssetURL } from '../../../helpers/util/AssetHelper';
import { NodeHeaderOptionsToDisplay } from '../../../types/enums';
import EditableWidget from '../../common/Widget/EditableWidget';
import type { WidgetFC } from '../../common/Widget/types';
import NodeHeader from '../../nodes/NodeHeader/NodeHeader';
import { useNodePageHeaderQuery } from './useNodePageHeaderQuery';
import { useNodePageHeaderText } from './useNodePageHeaderText';
import useWrapperSection from '../../useWrapperSection';
import localStyles from './NodeBannerWidget.module.pcss';
import type { NodeBannerProps } from './useNodeBannerWidgetFinalProps';
import { useNodeBannerWidgetFinalProps } from './useNodeBannerWidgetFinalProps';

/**
 * Banner Widget used for node pages
 *
 * @author Luisina Santos
 */
const NodeBannerWidget: WidgetFC<NodeBannerProps> = (props: NodeBannerProps) => {
  const tenant = useContext(TenantContext);

  const [propsLoading, finalProps] = useNodeBannerWidgetFinalProps(props);
  const quilt = useContext(QuiltContext);
  const quiltWrapper = useContext(QuiltWrapperContext);
  const cx = useClassNameMapper(localStyles);
  const { branchName } = useContext(SwitchBranchContext);

  const {
    useNodeHeaderCountStats,
    nodeHeaderVariantProps,
    showNodeHeaderTitleAndDescription,
    fontColor,
    backgroundColor,
    backgroundOpacity,
    backgroundImageProps,
    nodeHeaderPadding: { paddingBottom, paddingTop },
    visualEffects,
    className
  } = finalProps;

  const nodePageHeaderQueryResult = useNodePageHeaderQuery(
    useNodeHeaderCountStats,
    nodeHeaderVariantProps.type
  );

  const assetName: string = quilt.container.headerComponentProps
    ? quilt.container.headerComponentProps[EndUserComponent.BANNER_WIDGET]?.backgroundImageProps
        ?.assetName
    : null;

  const {
    loading: pageHeaderTextLoading,
    title,
    description,
    actionButtonTitle
  } = useNodePageHeaderText();

  const { sectionBackgroundStyles: bannerBgStyles } = useWrapperSection(
    quiltWrapper.id,
    {
      backgroundImageProps,
      backgroundColor
    },
    backgroundOpacity,
    assetName
      ? getQuiltAssetURL(
          tenant,
          quilt.id,
          assetName,
          AssetFeature.QUILTS,
          typeof backgroundImageProps.lastModified === 'string'
            ? backgroundImageProps.lastModified
            : LastModifiedKeys.DEFAULTS_ONLY,
          branchName
        )
      : null,
    true
  );

  if (pageHeaderTextLoading || propsLoading) {
    return null;
  }

  return (
    <EditableWidget<NodeBannerProps> props={props}>
      <QueryHandler queryResult={nodePageHeaderQueryResult}>
        {(): React.ReactNode => (
          <div className={cx('lia-container')}>
            <NodeHeader
              node={nodePageHeaderQueryResult.data.coreNode}
              nodeHeaderVariantProps={nodeHeaderVariantProps}
              title={title}
              description={description}
              actionButtonTitle={actionButtonTitle}
              showTitle={showNodeHeaderTitleAndDescription !== NodeHeaderOptionsToDisplay.NONE}
              showDescription={
                showNodeHeaderTitleAndDescription === NodeHeaderOptionsToDisplay.BOTH
              }
              fontColor={fontColor}
              useCountStats={useNodeHeaderCountStats}
              paddingTop={paddingTop}
              paddingBottom={paddingBottom}
              className={cx(className, 'lia-container-content')}
            />
            {!isObjectEmpty(bannerBgStyles) && (
              <div
                tabIndex={-1}
                data-testid="NodeBannerWidget.Background"
                className={cx('lia-container-bg', {
                  'border-bottom': visualEffects?.showBottomBorder
                })}
                style={bannerBgStyles}
              />
            )}
          </div>
        )}
      </QueryHandler>
    </EditableWidget>
  );
};

export default NodeBannerWidget;
